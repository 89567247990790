import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import './Planillas.css';
import { parseMuniSanJose, parseAya, parseCcss, parseContraloria, parseCenCinai, parseJPS, parseImas, parseIct, parseIctXls, parseSinac, parseMd, parseCd, parsePani } from '../parsePlanillas';
import xlsx from '../assets/xlsx.svg';
import {
    DocumentTableRegular,
    DocumentTableFilled,
    CheckRegular,
    CheckmarkRegular
} from "@fluentui/react-icons";

const db = getFirestore();

export default function Planillas() {
    const [dataList, setDataList] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('institucion');
    const [searchValue, setSearchValue] = useState('MUNICIPALIDAD DE SAN JOSE');
    const [institucion, setInstitucion] = useState('');
    // INTEGRA MD
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    // MUNI SAN JOSE
    const [muniSanJosefecha, setMuniSanJoseFecha] = useState('2022-01');
    // A Y A 
    const [periodoAya, setPeriodoAya] = useState('');
    const [rigeDesdeAya, setRigeDesdeAya] = useState('');
    // CCSS
    const [rigeDesdeCcss, SetRigeDesdeCcss] = useState('');
    const [rigeHastaCcss, SetRigeHastaCcss] = useState('');
    // HACIENDA
    const [fechaFormalizacion, setfechaFormalizacion] = useState('');
    // CENCINAI
    const [fechaDesdeCinai, setFechaDesdeCinai] = useState('');
    const [fechaHastaCinai, setFechaHastaCinai] = useState('');
    // JPS
    const [periodoJps, setPeriodoJps] = useState('');
    // IMAS 
    const [periodoImas, setPeriodoImas] = useState('');

    // POLIZAS
    const [polizasList, setPolizasList] = useState([]);

    // POLIZA MODE
    const [modoPoliza, setModoPoliza] = useState(false);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true)
        async function fetchData() {

            try {

                var polizaVTM525 = "TO7LtGbsjm4XGgLuJ0Af";

                const docSnap = await getDoc(doc(db, "polizas", polizaVTM525));

                if (docSnap.exists()) {
                    var array = [];
                    // console.log("Document data:", docSnap.data());

                    const q = query(collection(db, "polizas/" + polizaVTM525 + "/usuarios"));
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        // console.log(doc.id);
                        array.push({
                            key: doc.id,
                            id: parseInt(doc.data().afiliado),
                            ref: docSnap.data().id,
                            monto: docSnap.data().monto,
                            deduccion: docSnap.data().deduccion,
                            active: doc.data().active,
                            numero: doc.data().numero,
                            beneficiarios: doc.data().beneficiarios,
                            inicio: doc.data().inicio,
                        });
                    });

                } else {
                    console.log("No such document!");
                }

            } catch (error) {
                console.log(error)
            }
            setPolizasList(array);
            setLoading(false)


        }
        fetchData();
    }, []);


    function mdPoliza() {
        var desde = new Date(fechaDesde);
        var hasta = new Date(fechaHasta);

        // filter polizasList using inicio from desde hasta and also should ve active
        const filtered = polizasList.filter(item => item.inicio.seconds * 1000 >= desde && item.inicio.seconds * 1000 <= hasta && item.active);
        console.log(filtered);
        const array = [];
        filtered.forEach(element => {

            var startDate = new Date(element.inicio.seconds * 1000);
            var parsedStartDate = element.inicio && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

            const obj = {
                'displayName': element.ref,
                fechaAfiliacion: parsedStartDate,
                key: element.id.toString(),
                id: element.id,
                cedula: element.id,
                [`${element.ref} ESTADO`]: element.active ? 'ACTIVA' : 'INACTIVA',
                [`${element.ref} Deduccion`]: element.deduccion + ',00',
                [`${element.ref} Monto`]: element.monto,
                'rebajo': element.deduccion,
                'montoPoliza': element.monto,
            }
            array.push(obj);
        });
        console.log(array);
        return array;
        // parseMd(array);
    }


    function saveFile(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

    function saveSomeData(string) {
        const blob = new Blob([string], {
            type: 'application/json'
        });
        saveFile(blob, "datafile.csv")
    }

    const generate = () => {
        switch (searchValue) {
            case 'MUNICIPALIDAD DE SAN JOSE':
                parseMuniSanJose(dataList, muniSanJosefecha);
                break;
            case 'A Y A':
                parseAya(dataList, periodoAya, rigeDesdeAya);
                break;
            case 'CCSS':
                parseCcss(dataList, rigeDesdeCcss, rigeHastaCcss);
                break;
            case 'INTEGRA':
                // parseMd(dataList, fechaFormalizacion);
                parseCd(dataList, fechaFormalizacion);
                break;
            case 'CONTRALORIA GENERAL DE LA REPUBLICA':
                parseContraloria(dataList);
                break;
            case 'DIRECCION NACIONAL DE CEN CINAI':
                parseCenCinai(dataList, fechaDesdeCinai, fechaHastaCinai);
                break;
            case 'JUNTA DE PROTECCION SOCIAL':
                parseJPS(dataList, periodoJps);
                break;
            case 'IMAS':
                parseImas(dataList, periodoImas);
                break;
            case 'ICT':
                parseIct(dataList);
                break;
            case 'SINAC MINAE':
                parseSinac(dataList);
                break;
            case 'AEMAI,SANTA ANA':
                // parseCd(dataList, fechaFormalizacion);
                parseMd(dataList, fechaFormalizacion);
                break;
            default:
                // generateNormal(dataList);
                break;
        }
    }

    const match = async () => {
        try {
            const value = (searchCriteria === 'id') ? parseInt(searchValue) : searchValue;
            const q = query(collection(db, "usuarios"), where("active", "==", true), where(searchCriteria, "==", value), orderBy("ingreso", "desc"));
            const querySnapshot = await getDocs(q);

            var array = [];

            querySnapshot.forEach((doc) => {
                var data = doc.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);
                var dataObject = {
                    key: doc.id,
                    id: data.id,
                    cedula: data.id,
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    fechaAfiliacion: parsedStartDate,
                    poliza: data.poliza,
                }
                array.push(dataObject);
            });

            console.log(polizasList);

            array.forEach(element => {
                const equalId = polizasList.filter(item => element.id === item.id);
                // console.log(equalId);
                var counter = 0;
                element['Cantidad de polizas'] = counter;
                element['type'] = 'AFIL';
                equalId.forEach(res => {
                    counter = counter + 1;
                    element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                    element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                    element[res.ref + ' Monto'] = res.monto;
                });

                if (counter > 0 && element['VTM-525 ESTADO'] === 'ACTIVA') {
                    const copy = Object.assign({}, element);
                    copy.key = 'P' + copy.key;
                    copy['type'] = 'POLIZA';
                    array.push(copy);
                }
            });
            console.log(array);
        } catch (error) {
            console.log(error);
        }
        setDataList(array);
    }

    const generatedos = () => {
        // console.log(dataList);
        let csvContent = "";
        let prevLength = 0;
        var head = '';

        dataList.forEach((element, index) => {
            var objLength = Object.keys(element).length;
            var row = "";
            if (objLength > prevLength) {
                var header = Object.keys(element).join(';');
                head = header + "\r\n";
            }
            prevLength = objLength;
            var i = 0;
            for (const property in element) {
                i++
                if (i !== objLength) {
                    row += element[property].toString() + ";";
                } else {
                    row += element[property].toString();
                }
            }
            csvContent += row + "\r\n";
        });
        saveSomeData(head + csvContent);
    }



    const getAfil = async (input, type) => {
        try {
            console.log(input);

            var d = new Date(fechaDesde);
            var e = new Date(fechaHasta);

            console.log(type);

            if (type === 'AFIL') {
                console.log(1);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", true),
                    where("ingreso", ">", Timestamp.fromDate(d)),
                    where("institucion", "==", input),
                    where("ingreso", "<", Timestamp.fromDate(e)),
                    orderBy("ingreso", "desc")
                );
            } else if (type === 'DESA') {
                console.log(2);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", false),
                    where("desafiliacion", ">", Timestamp.fromDate(d)),
                    where("institucion", "==", input),
                    where("desafiliacion", "<", Timestamp.fromDate(e)),
                    orderBy("desafiliacion", "desc")
                );
            } else {
                console.log(3);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", true),
                    where("institucion", "==", input),
                    orderBy("ingreso", "desc")
                );
            }

            const querySnapshot = await getDocs(q);

            var array = [];

            var arrayP = [];

            querySnapshot.forEach((doc) => {
                var data = doc.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var endDate = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000) : data.desafiliacion;

                var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

                var dataObject = {
                    key: doc.id,
                    id: data.id,
                    cedula: data.id,
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    fechaAfiliacion: startDate,
                    referencia: data.referencia,
                    fechaFormalizacion: data.ingreso ? parsedStartDate : '',
                    fechaDesafiliacion: endDate,
                    // poliza: data.poliza,
                }
                array.push(dataObject);
            });


            array.forEach(element => {
                const equalId = polizasList.filter(item => element.id === item.id);

                var counter = 0;
                element['Cantidad de polizas'] = counter;
                element['type'] = 'AFIL';

                var rus;

                equalId.forEach(res => {
                    // console.log(res.inicio.seconds * 1000);

                    counter = counter + 1;
                    element['displayName'] = res.ref;
                    element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                    element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                    element[res.ref + ' Monto'] = res.monto;
                    element['rebajo'] = res.deduccion;
                    element['numero'] = res.numero ? res.numero : '';
                    element['montoPoliza'] = res.monto;
                    element['inicioPolizaTimestamp'] = res.inicio.seconds * 1000;

                    rus = res.active ? true : false;
                });

                console.log(rus, modoPoliza);


                console.log('XXXXXXXXXXXXXXX');
                if (counter > 0 && rus) {
                    const copy = Object.assign({}, element);
                    copy.key = 'P' + copy.key;
                    copy['type'] = 'POLIZA';
                    array.push(copy);
                    arrayP.push(copy);
                }





                // if (counter > 0 && element['VTM-525 ESTADO'] === 'ACTIVA') {
                //     const copy = Object.assign({}, element);
                //     copy.key = 'P' + copy.key;
                //     copy['type'] = 'POLIZA';
                //     array.push(copy);
                // }
            });



            console.log(array);
        } catch (error) {
            console.log(error);
        }
        setDataList(array);
        return array;
    }

    const d = new Date();

    const parse = (institucion, type) => {
        getAfil(institucion, type).then(result => {
            console.log(result);
            console.log(institucion, type);
            switch (institucion) {
                case 'A Y A':
                    if (modoPoliza) {
                        console.log(result.filter(t => t['type'] === 'POLIZA'))
                        parseAya(result.filter(t => t['type'] === 'POLIZA'), fechaDesde, fechaHasta, 'Planilla AYA ' + d.toLocaleDateString());
                    } else {
                        console.log(result.filter(t => t['type'] === 'AFIL'))
                        parseAya(result.filter(t => t['type'] === 'AFIL'), fechaDesde, fechaHasta, 'Planilla AYA ' + d.toLocaleDateString());
                    }

                    break;
                case 'MUNICIPALIDAD DE SAN JOSE':
                    if (modoPoliza) {
                        // console.log(result.filter(t => t['type'] === 'POLIZA'));
                        // parseAya(result.filter(t => t['type'] === 'POLIZA'), fechaDesde, fechaHasta, 'Planilla AYA ' + d.toLocaleDateString());
                        parseMuniSanJose(result.filter(t => t['type'] === 'POLIZA'), 'Planilla Municipalidad San Jose ' + d.toLocaleDateString());
                    } else {
                        // console.log(result.filter(t => t['type'] === 'AFIL'));
                        // parseAya(result.filter(t => t['type'] === 'AFIL'), fechaDesde, fechaHasta, 'Planilla AYA ' + d.toLocaleDateString());
                        parseMuniSanJose(result.filter(t => t['type'] === 'AFIL'), 'Planilla Municipalidad San Jose ' + d.toLocaleDateString());
                    }

                    break;
                case 'CCSS':
                    parseCcss(result, rigeDesdeCcss, rigeHastaCcss, 'Planilla CCSS ' + d.toLocaleDateString());
                    break;
                case 'CONTRALORIA GENERAL DE LA REPUBLICA':
                    parseContraloria(result, 'Planilla Contraloria General de la Republica ' + d.toLocaleDateString());
                    break;
                case 'DIRECCION NACIONAL DE CEN CINAI':
                    parseCenCinai(result, fechaDesdeCinai, fechaHastaCinai, 'Planilla Cen Cinai ' + d.toLocaleDateString());
                    break;
                case 'JUNTA DE PROTECCION SOCIAL':
                    parseJPS(result, periodoJps, 'Planilla Junta de Proteccion Social ' + d.toLocaleDateString());
                    break;
                case 'IMAS':
                    parseImas(result, periodoImas, 'Planilla IMAS ' + d.toLocaleDateString());
                    break;
                case 'ICT':
                    parseIct(result, 'Planilla ICT ' + d.toLocaleDateString());
                    parseIctXls(result, 'Planilla ICT ' + d.toLocaleDateString());
                    break;
                case 'SINAC MINAE':
                    parseSinac(result, 'Planilla SINAC ' + d.toLocaleDateString());
                    break;
                case 'PATRONATO NACIONAL DE LA INFANCIA':
                    parsePani(result, fechaDesdeCinai, fechaHastaCinai, 'Planilla PANI ' + d.toLocaleDateString());
                    break;
                default:
                    break;
            }
        });
    }


    useEffect(() => {
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var monthAgo = ("0" + (now.getMonth())).slice(-2);
        var today = now.getFullYear() + "-" + month + "-" + day;
        var currentMonth = now.getFullYear() + "-" + month;
        setFechaDesde(now.getFullYear() + '-' + monthAgo + '-' + day);
        setFechaHasta(today);
        setPeriodoJps(currentMonth);
        setPeriodoImas(currentMonth);
        setFechaDesdeCinai(today);
        setFechaHastaCinai(today);
    }, []);

    const generateMD = async () => {

        setSearchValue('INTEGRA');

        var d = new Date(fechaDesde);
        var e = new Date(fechaHasta);

        const q1 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'ASAMBLEA LEGISLATIVA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        const q2 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DEFENSORIA DE LOS HABITANTES'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        // const q3 = query(collection(db, "usuarios"),
        //     where("active", "==", true),
        //     where('institucion', "==", 'JUNTA DE PENSIONES MAGISTERIO NACIONAL'),
        //     where("ingreso", ">", Timestamp.fromDate(d)),
        //     where("ingreso", "<", Timestamp.fromDate(e))
        // );

        const q4 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE AGRICULTURA Y GANADERIA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        const q5 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CIENCIA Y TECNOLOGIA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        const q6 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CULTURA Y JUVENTUD'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        const q7 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE GOBERNACION Y POLICIA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );

        const q8 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q9 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE EDUCACION PUBLICA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q10 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE HACIENDA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q11 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE JUSTICIA Y PAZ'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q12 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE LA PRESIDENCIA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q13 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q14 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE RELACIONES EXTERIORES Y CULTO'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q15 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SALUD'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q16 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SEGURIDAD PUBLICA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q17 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q18 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q19 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'PRESIDENCIA DE LA REPUBLICA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q20 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q21 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'TRIBUNAL SUPREMO ELECCIONES'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q22 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION NACIONAL PENSIONES'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q23 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q24 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'IMPRENTA NACIONAL'),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q25 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MINISTERIO DE AMBIENTE Y ENERGIA"),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q26 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "DIRECCION GENERAL DE SERVICIO CIVIL"),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q27 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MIDEPLAN"),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );
        const q28 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MINISTERIO DE RELACIONES EXTERIORES Y CULTO"),
            where("ingreso", ">", Timestamp.fromDate(d)),
            where("ingreso", "<", Timestamp.fromDate(e))
        );


        var res = await Promise.all([
            getDocs(q1),
            getDocs(q2),
            getDocs(q4),
            getDocs(q5),
            getDocs(q6),
            getDocs(q7),
            getDocs(q8),
            getDocs(q9),
            getDocs(q10),
            getDocs(q11),
            getDocs(q12),
            getDocs(q13),
            getDocs(q14),
            getDocs(q15),
            getDocs(q16),
            getDocs(q17),
            getDocs(q18),
            getDocs(q19),
            getDocs(q20),
            getDocs(q21),
            getDocs(q22),
            getDocs(q23),
            getDocs(q24),
            getDocs(q25),
            getDocs(q26),
            getDocs(q27),
            getDocs(q28),
        ]);

        var array = []

        res.forEach(snap => {
            // console.log(snap);
            snap.forEach(d => {
                var data = d.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var endDate = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000) : data.desafiliacion;

                var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

                var dataObject = {
                    key: data.id.toString(),
                    id: data.id,
                    cedula: data.id,
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    fechaAfiliacion: parsedStartDate,
                    // fechaDesafiliacion: endDate,
                    poliza: data.poliza,
                    type: 'AFIL'

                }
                array.push(dataObject);
            })
        });

        mdPoliza().forEach(element => {
            array.push(element);
        });
        // console.log(array);

        // array.forEach(element => {
        //     const equalId = polizasList.filter(item => element.id === item.id);
        //     var counter = 0;
        //     // element['Cantidad de polizas'] = counter;
        //     element['type'] = 'AFIL';

        //     var rus;
        //     equalId.forEach(res => {
        //         counter = counter + 1;
        //         element['displayName'] = res.ref;
        //         element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
        //         element[res.ref + ' Deduccion'] = res.deduccion + ',00';
        //         element[res.ref + ' Monto'] = res.monto;
        //         element['rebajo'] = res.deduccion;
        //         element['montoPoliza'] = res.monto;

        //         rus = res.active ? true : false;
        //     });

        //     if (counter > 0 && rus) {
        //         const copy = Object.assign({}, element);
        //         copy.key = 'P' + copy.key;
        //         copy['type'] = 'POLIZA';
        //         array.push(copy);
        //     }
        // });

        console.log(array);
        setDataList(array);
        parseMd(array);

    }

    const generateMDall = async () => {

        setSearchValue('INTEGRA');

        var d = new Date(fechaDesde);
        var e = new Date(fechaHasta);

        const q1 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'ASAMBLEA LEGISLATIVA')
        );

        const q2 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DEFENSORIA DE LOS HABITANTES')
        );

        // const q3 = query(collection(db, "usuarios"),
        //     where("active", "==", true),
        //     where('institucion', "==", 'JUNTA DE PENSIONES MAGISTERIO NACIONAL'),
        //     where("ingreso", ">", Timestamp.fromDate(d)),
        //     where("ingreso", "<", Timestamp.fromDate(e))
        // );

        const q4 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE AGRICULTURA Y GANADERIA')
        );
        const q5 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CIENCIA Y TECNOLOGIA')
        );
        const q6 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CULTURA Y JUVENTUD')
        );
        const q7 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE GOBERNACION Y POLICIA')
        );
        const q8 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO')
        );
        const q9 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE EDUCACION PUBLICA')
        );
        const q10 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE HACIENDA')
        );
        const q11 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE JUSTICIA Y PAZ')
        );
        const q12 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE LA PRESIDENCIA')
        );
        const q13 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE')
        );
        const q14 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE RELACIONES EXTERIORES Y CULTO')
        );
        const q15 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SALUD')
        );
        const q16 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SEGURIDAD PUBLICA')
        );
        const q17 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL')
        );
        const q18 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS')
        );
        const q19 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'PRESIDENCIA DE LA REPUBLICA')
        );
        const q20 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)')
        );
        const q21 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'TRIBUNAL SUPREMO ELECCIONES')
        );
        const q22 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION NACIONAL PENSIONES')
        );
        const q23 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA')
        );
        const q24 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'IMPRENTA NACIONAL')
        );

        const q25 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MINISTERIO DE AMBIENTE Y ENERGIA")
        );

        const q26 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "DIRECCION GENERAL DE SERVICIO CIVIL")
        );

        const q27 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MIDEPLAN")
        );

        const q28 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MINISTERIO DE RELACIONES EXTERIORES Y CULTO")
        );


        // MIGRACION
        // SERVICIO CIVIL
        // TRIBUNAL DEL SERVICIO CIVIL
        // SERVICIO EXTERIOR
        // PROCUDARUDIA
        // MINISTERIO DE AMBIENTE


        var res = await Promise.all([
            getDocs(q1),
            getDocs(q2),
            getDocs(q4),
            getDocs(q5),
            getDocs(q6),
            getDocs(q7),
            getDocs(q8),
            getDocs(q9),
            getDocs(q10),
            getDocs(q11),
            getDocs(q12),
            getDocs(q13),
            getDocs(q14),
            getDocs(q15),
            getDocs(q16),
            getDocs(q17),
            getDocs(q18),
            getDocs(q19),
            getDocs(q20),
            getDocs(q21),
            getDocs(q22),
            getDocs(q23),
            getDocs(q24),
            getDocs(q25),
            getDocs(q26),
            getDocs(q27),
            getDocs(q28)
        ]);

        var array = []

        res.forEach(snap => {
            // console.log(snap);
            snap.forEach(d => {
                var data = d.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var endDate = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000) : data.desafiliacion;

                var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

                var dataObject = {
                    key: data.id.toString(),
                    id: data.id,
                    cedula: data.id,
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    fechaAfiliacion: parsedStartDate,
                    // fechaDesafiliacion: endDate,
                    poliza: data.poliza,
                }
                array.push(dataObject);
            })
        });
        console.log(array);

        array.forEach(element => {
            const equalId = polizasList.filter(item => element.id === item.id);
            var counter = 0;
            // element['Cantidad de polizas'] = counter;
            element['type'] = 'AFIL';

            var rus;
            equalId.forEach(res => {
                counter = counter + 1;
                element['displayName'] = res.ref;
                element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                element[res.ref + ' Monto'] = res.monto;
                element['rebajo'] = res.deduccion;
                element['montoPoliza'] = res.monto;

                rus = res.active ? true : false;
            });

            if (counter > 0 && rus) {
                const copy = Object.assign({}, element);
                copy.key = 'P' + copy.key;
                copy['type'] = 'POLIZA';
                array.push(copy);
            }
        });

        // console.log(array);
        // setDataList(array);
        // parseMd(array);

        var filteredArray = array.filter((item) => item['type'] !== 'AFIL');
        console.log(filteredArray);
        setDataList(filteredArray);
        parseMd(filteredArray);

    }
    const generateCD = async () => {

        const q1 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'ASAMBLEA LEGISLATIVA')
        );

        const q2 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DEFENSORIA DE LOS HABITANTES')
        );

        // const q3 = query(collection(db, "usuarios"),
        //     where("active", "==", true),
        //     where('institucion', "==", 'JUNTA DE PENSIONES MAGISTERIO NACIONAL')
        // );

        const q4 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE AGRICULTURA Y GANADERIA')
        );

        const q5 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CIENCIA Y TECNOLOGIA')
        );

        const q6 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE CULTURA Y JUVENTUD')
        );

        const q7 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE GOBERNACION Y POLICIA')
        );

        const q8 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO')
        );
        const q9 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE EDUCACION PUBLICA')
        );
        const q10 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE HACIENDA')
        );
        const q11 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE JUSTICIA Y PAZ')
        );
        const q12 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE LA PRESIDENCIA')
        );
        const q13 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE')
        );
        const q14 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE RELACIONES EXTERIORES Y CULTO')
        );
        const q15 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SALUD')
        );
        const q16 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE SEGURIDAD PUBLICA')
        );
        const q17 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL')
        );
        const q18 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS')
        );
        const q19 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'PRESIDENCIA DE LA REPUBLICA')
        );
        const q20 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)')
        );
        const q21 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'TRIBUNAL SUPREMO ELECCIONES')
        );
        const q22 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION NACIONAL PENSIONES')
        );
        const q23 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA')
        );
        const q24 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", 'IMPRENTA NACIONAL')
        );

        const q25 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MINISTERIO DE AMBIENTE Y ENERGIA")
        );

        const q26 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "DIRECCION GENERAL DE SERVICIO CIVIL")
        );

        const q27 = query(collection(db, "usuarios"),
            where("active", "==", true),
            where('institucion', "==", "MIDEPLAN")
        );

        // const q28 = query(collection(db, "usuarios"),
        //     where("active", "==", true),
        //     where('institucion', "==", "MINISTERIO DE RELACIONES EXTERIORES Y CULTO")
        // );


        var res = await Promise.all([
            getDocs(q1),
            getDocs(q2),
            getDocs(q4),
            getDocs(q5),
            getDocs(q6),
            getDocs(q7),
            getDocs(q8),
            getDocs(q9),
            getDocs(q10),
            getDocs(q11),
            getDocs(q12),
            getDocs(q13),
            getDocs(q14),
            getDocs(q15),
            getDocs(q16),
            getDocs(q17),
            getDocs(q18),
            getDocs(q19),
            getDocs(q20),
            getDocs(q21),
            getDocs(q22),
            getDocs(q23),
            getDocs(q24),
            getDocs(q25),
            getDocs(q26),
            getDocs(q27),
            // getDocs(q28)
        ]);

        var array = []

        res.forEach(snap => {
            snap.forEach(d => {
                var data = d.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var expireDate = new Date(data.expira.seconds * 1000);
                var nowDate = Date.now();
                var isActive = expireDate.getTime() > nowDate;

                var parsedStartDate = data.ingreso && startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2);

                var dataObject = {
                    key: d.id,
                    id: data.id,
                    cedula: data.id,
                    type: 'AFIL',
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    estado: isActive,
                    fechaAfiliacion: parsedStartDate,
                }
                array.push(dataObject);

            })
        });

        array.forEach(element => {
            const equalId = polizasList.filter(item => element.id === item.id);
            var counter = 0;
            element['Cantidad de polizas'] = counter;
            element['type'] = 'AFIL';

            var rus;
            equalId.forEach(res => {
                counter = counter + 1;
                element['displayName'] = res.ref;
                element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                element[res.ref + ' Monto'] = res.monto;
                element['rebajo'] = res.deduccion;
                element['montoPoliza'] = res.monto;

                rus = res.active ? true : false;
            });

            if (counter > 0 && rus) {
                const copy = Object.assign({}, element);
                copy.key = 'P' + copy.key;
                copy['type'] = 'POLIZA';
                array.push(copy);
            }
        });
        console.log(array);
        setDataList(array);
        parseCd(array);
    }


    return (
        <div className="page planillas">
            <div className='planillas-header'>
                <h1>Planillas</h1>
                <h4>Items: {dataList.length}</h4>
            </div>

            {/* <h4>Institucion</h4>
            <div className='input-container'>
                <select id="planilla-institucion" value={searchValue} onChange={e => setSearchValue(e.target.value)}>
                    <option value="" disabled>---Selecciona una institucion---</option>
                    <option value="MUNICIPALIDAD DE SAN JOSE">Municipalidad de San Jose</option>
                </select>
            </div> */}

            {
                loading && <p>Espera...</p>
            }

            <div className='planilla-card'>
                <h3>Integra</h3>

                <div className='planillas-dates-container'>
                    <div>
                        <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesde} onChange={e => setFechaDesde(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHasta} onChange={e => setFechaHasta(e.target.value)}></input>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <button className='filled-tonal-button' onClick={generateCD}><DocumentTableRegular fontSize={24} />Planilla CD</button>

                    <button className='filled-tonal-button' onClick={generateMD}><DocumentTableRegular fontSize={24} />Planilla MD</button>

                    <button className='filled-tonal-button' onClick={mdPoliza}><DocumentTableRegular fontSize={24} />MD solo póliza</button>

                    <button className='filled-tonal-button' onClick={generateMDall}><DocumentTableRegular fontSize={24} />Planilla MD todos poliza</button>
                </div>
            </div>



            <div className='planilla-card'>
                <h3>A Y A</h3>

                <div style={{ marginBottom: '1rem' }}>
                    {modoPoliza ? <h5 style={{ marginBottom: '0.25rem' }}>Modo Poliza Activo</h5> : <h5 style={{ marginBottom: '0.25rem' }}>Modo Poliza Inactivo</h5>}

                    <button className={(modoPoliza) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => setModoPoliza(!modoPoliza)}>
                        <div className={(modoPoliza) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                    </button>
                </div>

                <div className='planillas-dates-container'>
                    <div>
                        <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesde} onChange={e => setFechaDesde(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHasta} onChange={e => setFechaHasta(e.target.value)}></input>
                    </div>
                </div>

                {
                    modoPoliza ?
                        <button className='filled-tonal-button' onClick={() => parse('A Y A')}><DocumentTableRegular fontSize={24} />Planilla AYA Poliza</button> :
                        <div>
                            <button style={{ marginBottom: '1rem' }} className='filled-tonal-button' onClick={() => parse('A Y A', 'AFIL')}><DocumentTableRegular fontSize={24} />Planilla AYA AFILIADOS en rango</button>

                            <button style={{ marginBottom: '1rem' }} className='filled-tonal-button' onClick={() => parse('A Y A', 'DESA')}><DocumentTableRegular fontSize={24} />Planilla AYA DESAFILIADOS en rango</button>
                        </div>

                }

            </div>

            <MunicipalidadSanJoseCard />

            <div className='planilla-card'>
                <h3>CCSS</h3>

                <button className='filled-tonal-button' onClick={() => parse('CCSS')}><DocumentTableRegular fontSize={24} />Planilla CCSS</button>
            </div>

            <div className='planilla-card'>
                <h3>Contraloria General de la Republica</h3>

                <button className='filled-tonal-button' onClick={() => parse('CONTRALORIA GENERAL DE LA REPUBLICA')}><DocumentTableRegular fontSize={24} />Planilla Contraloria General de la Republica</button>
            </div>

            <div className='planilla-card'>
                <h3>Direccion General de CEN-CINAI</h3>

                <div className='planillas-dates-container'>
                    <div>
                        <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde CEN-CINAI</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesdeCinai} onChange={e => setFechaDesdeCinai(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta CEN-CINAI</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHastaCinai} onChange={e => setFechaHastaCinai(e.target.value)}></input>
                    </div>
                </div>

                <button className='filled-tonal-button' onClick={() => parse('DIRECCION NACIONAL DE CEN CINAI')}><DocumentTableRegular fontSize={24} />Planilla CEN-CINAI</button>
            </div>

            <div className='planilla-card'>
                <h3>Junta de Proteccion Social</h3>

                <div className='planillas-dates-container'>
                    <div className='input-container'>
                        <label htmlFor='planilla-periodo-jps'>Periodo JPS</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' id="planilla-periodo-jps" type="month" value={periodoJps} onChange={e => { setPeriodoJps(e.target.value) }} />
                    </div>
                </div>

                <button className='filled-tonal-button' onClick={() => parse('JUNTA DE PROTECCION SOCIAL')}><DocumentTableRegular fontSize={24} />Planilla Junta de Proteccion Social</button>
            </div>

            <div className='planilla-card'>
                <h3>IMAS</h3>

                <div className='planillas-dates-container'>
                    <div className='input-container'>
                        <label htmlFor='planilla-periodo-jps'>Periodo IMAS</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' id="planilla-periodo-jps" type="month" value={periodoImas} onChange={e => { setPeriodoImas(e.target.value) }} />
                    </div>
                </div>

                <button className='filled-tonal-button' onClick={() => parse('IMAS')}><DocumentTableRegular fontSize={24} />Planilla IMAS</button>
            </div>

            <div className='planilla-card'>
                <h3>Instituto Nacional de Turismo</h3>

                <button className='filled-tonal-button' onClick={() => parse('ICT')}><DocumentTableRegular fontSize={24} />Planilla ICT</button>
            </div>

            <div className='planilla-card'>
                <h3>SINAC</h3>

                <button className='filled-tonal-button' onClick={() => parse('SINAC MINAE')}><DocumentTableRegular fontSize={24} />Planilla SINAC</button>
            </div>

            <div className='planilla-card'>
                <h3>Patronato Nacional de la Infancia</h3>

                <div className='planillas-dates-container'>
                    <div>
                        <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde PANI</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesdeCinai} onChange={e => setFechaDesdeCinai(e.target.value)}></input>
                    </div>
                    <div>
                        <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta PANi</label>
                        <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHastaCinai} onChange={e => setFechaHastaCinai(e.target.value)}></input>
                    </div>
                </div>

                <button className='filled-tonal-button' onClick={() => parse('PATRONATO NACIONAL DE LA INFANCIA')}><DocumentTableRegular fontSize={24} />Planilla PANI</button>
            </div>

        </div>
    );
}

function MunicipalidadSanJoseCard() {
    // INTEGRA MD
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [polizasList, setPolizasList] = useState([]);
    const [modoPoliza, setModoPoliza] = useState(false);
    const [inclusion, setInclusion] = useState(true);

    const filterPolizaMuniSJ = async () => {
        try {
            var polizaVTM525 = "TO7LtGbsjm4XGgLuJ0Af";

            const docSnap = await getDoc(doc(db, "polizas", polizaVTM525));

            if (docSnap.exists()) {
                var array = [];

                console.log("Document data:", docSnap.data());

                if (fechaDesde) {
                    var d = new Date(fechaDesde);
                    var e = new Date(fechaHasta);
                    console.log('VERDADERO');
                    var q = query(
                        collection(db, "polizas/" + polizaVTM525 + "/usuarios"),
                        where("inicio", ">", Timestamp.fromDate(d)),
                        where("inicio", "<", Timestamp.fromDate(e)),
                        where("active", "==", true)
                    );
                } else if (fechaDesde) {
                    var d = new Date(fechaDesde);
                    var e = new Date(fechaHasta);
                    console.log('FALSO');
                    var q = query(
                        collection(db, "polizas/" + polizaVTM525 + "/usuarios"),
                        where("fin", ">", Timestamp.fromDate(d)),
                        where("fin", "<", Timestamp.fromDate(e)),
                        where("active", "==", false)
                    );
                } else {
                    alert('Debes seleccionar Fecha Desde');
                }

                // const q = query(collection(db, "polizas/" + polizaVTM525 + "/usuarios"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    // console.log(doc.id);
                    array.push({
                        key: doc.id,
                        id: parseInt(doc.data().afiliado),
                        ref: docSnap.data().id,
                        monto: docSnap.data().monto,
                        deduccion: docSnap.data().deduccion,
                        active: doc.data().active,
                        beneficiarios: doc.data().beneficiarios
                    });
                });


            } else {
                console.log("No such document!");
            }

        } catch (error) {
            console.log(error)
        }

        setPolizasList(array);

    }

    useEffect(() => {
        async function fetchData() {

            try {
                var polizaVTM525 = "TO7LtGbsjm4XGgLuJ0Af";
                const docSnap = await getDoc(doc(db, "polizas", polizaVTM525));
                if (docSnap.exists()) {
                    if (modoPoliza) {

                        var array = [];

                        if (inclusion) {
                            var d = new Date(fechaDesde);
                            var e = new Date(fechaHasta);
                            console.log('VERDADERO');
                            var q = query(
                                collection(db, "polizas/" + polizaVTM525 + "/usuarios"),
                                where("inicio", ">", Timestamp.fromDate(d)),
                                where("inicio", "<", Timestamp.fromDate(e)),
                                where("active", "==", true)
                            );
                        } else {
                            var d = new Date(fechaDesde);
                            var e = new Date(fechaHasta);
                            console.log('FALSO');
                            var q = query(
                                collection(db, "polizas/" + polizaVTM525 + "/usuarios"),
                                where("fin", ">", Timestamp.fromDate(d)),
                                where("fin", "<", Timestamp.fromDate(e)),
                                where("active", "==", false)
                            );
                        }

                        const querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.id);
                            array.push({
                                key: doc.id,
                                id: parseInt(doc.data().afiliado),
                                ref: docSnap.data().id,
                                monto: docSnap.data().monto,
                                deduccion: docSnap.data().deduccion,
                                active: doc.data().active,
                                beneficiarios: doc.data().beneficiarios
                            });
                        });

                    } else {
                        var array = [];
                        const q = query(collection(db, "polizas/" + polizaVTM525 + "/usuarios"));
                        const querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.id);
                            array.push({
                                key: doc.id,
                                id: parseInt(doc.data().afiliado),
                                ref: docSnap.data().id,
                                monto: docSnap.data().monto,
                                deduccion: docSnap.data().deduccion,
                                active: doc.data().active,
                                beneficiarios: doc.data().beneficiarios
                            });
                        });
                    }

                    console.log("Document data:", docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log(error)
            }
            console.log(array);
            setPolizasList(array);

        }
        fetchData();
    }, [modoPoliza, inclusion]);

    const getAfil = async (input, type) => {
        try {
            var d = new Date(fechaDesde);
            var e = new Date(fechaHasta);

            if (type === 'AFIL') {
                console.log(1);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", true),
                    where("ingreso", ">", Timestamp.fromDate(d)),
                    where("institucion", "==", input),
                    where("ingreso", "<", Timestamp.fromDate(e)),
                    orderBy("ingreso", "desc")
                );
            } else if (type === 'DESA') {
                console.log(2);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", false),
                    where("desafiliacion", ">", Timestamp.fromDate(d)),
                    where("institucion", "==", input),
                    where("desafiliacion", "<", Timestamp.fromDate(e)),
                    orderBy("desafiliacion", "desc")
                );
            } else {
                console.log(3);
                var q = query(collection(db, "usuarios"),
                    where("active", "==", true),
                    where("institucion", "==", input),
                    orderBy("ingreso", "desc")
                );
            }

            const querySnapshot = await getDocs(q);

            var array = [];

            var arrayP = [];

            querySnapshot.forEach((doc) => {
                var data = doc.data();
                var startDate = new Date(data.ingreso.seconds * 1000);
                var endDate = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000) : data.desafiliacion;
                var dataObject = {
                    key: doc.id,
                    id: data.id,
                    cedula: data.id,
                    nombre: data.nombre,
                    lastname: data.apellidos,
                    institucion: data.institucion,
                    referencia: data.referencia,
                    fechaAfiliacion: startDate,
                    fechaDesafiliacion: endDate,
                    clase: type
                    // poliza: data.poliza,
                }
                array.push(dataObject);
            });

            array.forEach(element => {
                const equalId = polizasList.filter(item => element.id === item.id);
                var counter = 0;
                element['Cantidad de polizas'] = counter;
                element['type'] = 'AFIL';

                var rus;
                equalId.forEach(res => {
                    counter = counter + 1;
                    element['displayName'] = res.ref;
                    element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                    element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                    element[res.ref + ' Monto'] = res.monto;
                    element['rebajo'] = res.deduccion;
                    element['montoPoliza'] = res.monto;

                    rus = res.active ? true : false;
                });

                if (counter > 0 && rus) {
                    const copy = Object.assign({}, element);
                    copy.key = 'P' + copy.key;
                    copy['type'] = 'POLIZA';
                    array.push(copy);
                    arrayP.push(copy);
                }
            });

            console.log(array);
        } catch (error) {
            console.log(error);
        }
        // setDataList(array);
        return array;
    }

    const d = new Date();
    const parse = (institucion, type) => {
        getAfil(institucion, type).then(result => {
            console.log(result);
            console.log(institucion, type);
            switch (institucion) {
                case 'MUNICIPALIDAD DE SAN JOSE':
                    if (modoPoliza) {
                        console.log(result.filter(t => t['type'] === 'POLIZA'));
                        parseMuniSanJose(result.filter(t => t['type'] === 'POLIZA'), 'Planilla Municipalidad San Jose ' + d.toLocaleDateString());
                    } else {
                        console.log(result.filter(t => t['type'] === 'AFIL'));
                        parseMuniSanJose(result.filter(t => t['type'] === 'AFIL'), 'Planilla Municipalidad San Jose ' + d.toLocaleDateString());
                    }
                    break;
            }
        });
    }


    useEffect(() => {
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var monthAgo = ("0" + (now.getMonth())).slice(-2);
        var today = now.getFullYear() + "-" + month + "-" + day;
        setFechaDesde(now.getFullYear() + '-' + monthAgo + '-' + day);
        setFechaHasta(today);
    }, []);


    return (
        <div className='planilla-card'>
            <h3>Municipalidad de San Jose</h3>

            <div style={{ marginBottom: '1rem' }}>
                {modoPoliza ? <h5 style={{ marginBottom: '0.25rem' }}>Modo Poliza Activo</h5> : <h5 style={{ marginBottom: '0.25rem' }}>Modo Poliza Inactivo</h5>}

                <button className={(modoPoliza) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => setModoPoliza(!modoPoliza)}>
                    <div className={(modoPoliza) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                </button>
            </div>

            <div className='planillas-dates-container'>
                <div>
                    <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde</label>
                    <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesde} onChange={e => setFechaDesde(e.target.value)}></input>
                </div>
                <div>
                    <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta</label>
                    <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHasta} onChange={e => setFechaHasta(e.target.value)}></input>
                </div>
            </div>




            {
                modoPoliza ?
                    <div>
                        <div style={{ marginBottom: '1rem' }}>
                            {inclusion ? <h5 style={{ marginBottom: '0.25rem' }}>Inclusiones</h5> : <h5 style={{ marginBottom: '0.25rem' }}>Exclusiones</h5>}

                            <button className={(inclusion) ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => setInclusion(!inclusion)}>
                                <div className={(inclusion) ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                            </button>
                        </div>
                        <button className='filled-tonal-button' onClick={() => parse('MUNICIPALIDAD DE SAN JOSE')}><DocumentTableRegular fontSize={24} />Planilla Municipalidad de San Jose Poliza</button>
                    </div> :
                    <div>
                        <button style={{ marginBottom: '1rem' }} className='filled-tonal-button' onClick={() => parse('MUNICIPALIDAD DE SAN JOSE', 'AFIL')}><DocumentTableRegular fontSize={24} />Planilla Municipalidad de San Jose AFILIADOS en rango</button>

                        <button style={{ marginBottom: '1rem' }} className='filled-tonal-button' onClick={() => parse('MUNICIPALIDAD DE SAN JOSE', 'DESA')}><DocumentTableRegular fontSize={24} />Planilla Municipalidad de San Jose DESAFILIADOS en rango</button>
                    </div>

            }

        </div>
    )
}