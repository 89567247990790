import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import './GeneradorINS.css';
import xlsx from '../assets/xlsx.svg';

const db = getFirestore();

export default function GeneradorINS() {
    const [dataList, setDataList] = useState([]);
    const [polizasList, setPolizasList] = useState([]);

    const [polizasGeneralesList, setPolizasGeneralesList] = useState([]);
    const [selectedPoliza, setSelectedPoliza] = useState('TO7LtGbsjm4XGgLuJ0Af');

    const [searchCriteria, setSearchCriteria] = useState('institucion');
    const [searchValue, setSearchValue] = useState('AEMAI,SANTA ANA');

    const [vigencia, setVigencia] = useState('');


    const [nuevos, setNuevos] = useState(true);
    const [afiliadoActivo, setAfiliadoActivo] = useState(true);
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');

    // Troubleshoot
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, "polizas"), where("active", "==", true));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach(async (poliza) => {
                array.push({
                    key: poliza.id,
                    id: poliza.data().id,
                    monto: poliza.data().monto,
                    active: poliza.data().active,
                    deduccion: poliza.data().deduccion
                });
            });
            setPolizasGeneralesList(array);
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             var polizaVTM525 = "TO7LtGbsjm4XGgLuJ0Af";
    //             const docSnap = await getDoc(doc(db, "polizas", polizaVTM525));
    //             if (docSnap.exists()) {
    //                 var array = [];
    //                 console.log("Document data:", docSnap.data());
    //                 const q = query(collection(db, "polizas/" + polizaVTM525 + "/usuarios"));
    //                 const querySnapshot = await getDocs(q);
    //                 querySnapshot.forEach((doc) => {
    //                     var obj = {
    //                         key: doc.id,
    //                         id: parseInt(doc.data().afiliado),
    //                         ref: docSnap.data().id,
    //                         monto: docSnap.data().monto,
    //                         deduccion: docSnap.data().deduccion,
    //                         active: doc.data().active,
    //                         beneficiarios: doc.data().beneficiarios
    //                     }
    //                     // console.log(obj);
    //                     array.push(obj);
    //                 });
    //             } else {
    //                 console.log("No such document!");
    //             }
    //         } catch (error) {
    //             console.log(error)
    //         }
    //         setPolizasList(array);
    //     }
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             const docSnap = await getDoc(doc(db, "polizas", selectedPoliza));
    //             if (docSnap.exists()) {
    //                 var array = [];
    //                 console.log("Document data:", docSnap.data());
    //                 const q = query(collection(db, "polizas/" + selectedPoliza + "/usuarios"));
    //                 const querySnapshot = await getDocs(q);
    //                 querySnapshot.forEach((doc) => {
    //                     var obj = {
    //                         key: doc.id,
    //                         id: parseInt(doc.data().afiliado),
    //                         ref: docSnap.data().id,
    //                         monto: docSnap.data().monto,
    //                         deduccion: docSnap.data().deduccion,
    //                         active: doc.data().active,
    //                         beneficiarios: doc.data().beneficiarios
    //                     }
    //                     array.push(obj);
    //                 });
    //             } else {
    //                 console.log("No such document!");
    //             }
    //         } catch (error) {
    //             console.log(error)
    //         }
    //         setPolizasList(array);
    //     }
    //     fetchData();
    // }, [selectedPoliza]);

    // useEffect(() => {
    //     async function fetchData() {
    //         if (fechaDesde) {

    //             var d = new Date(fechaDesde);
    //             var e = new Date(fechaHasta);
    //             try {
    //                 const docSnap = await getDoc(doc(db, "polizas", selectedPoliza));
    //                 if (docSnap.exists()) {
    //                     var array = [];
    //                     console.log("Document data:", docSnap.data());

    //                     const q = query(
    //                         collection(db, "polizas/" + selectedPoliza + "/usuarios"),
    //                         where("inicio", ">", Timestamp.fromDate(d)),
    //                         where("inicio", "<", Timestamp.fromDate(e))
    //                     );

    //                     const querySnapshot = await getDocs(q);
    //                     querySnapshot.forEach((doc) => {
    //                         var obj = {
    //                             key: doc.id,
    //                             id: parseInt(doc.data().afiliado),
    //                             ref: docSnap.data().id,
    //                             monto: docSnap.data().monto,
    //                             deduccion: docSnap.data().deduccion,
    //                             active: doc.data().active,
    //                             beneficiarios: doc.data().beneficiarios
    //                         }
    //                         array.push(obj);
    //                     });
    //                 } else {
    //                     console.log("No such document!");
    //                 }
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //             setPolizasList(array);

    //         } else {
    //             console.log('falta fecha desde');
    //         }

    //     }
    //     fetchData();
    // }, [fechaDesde, fechaHasta]);

    useEffect(() => {
        async function fetchData() {

            console.log(selectedPoliza);
            setLoading(true);

            try {
                const docSnap = await getDoc(doc(db, "polizas", selectedPoliza));
                if (docSnap.exists()) {
                    var array = [];
                    // console.log(nuevos);
                    if (fechaDesde && nuevos || fechaDesde && !nuevos) {
                        var desde = new Date(fechaDesde);
                        var hasta = new Date(fechaHasta);
                        hasta.setDate(hasta.getDate() + 1);
                        console.log('VERDADERO');
                        var q = query(
                            collection(db, "polizas/" + selectedPoliza + "/usuarios"),
                            where("inicio", ">", Timestamp.fromDate(desde)),
                            where("inicio", "<", Timestamp.fromDate(hasta)),
                            where("active", "==", nuevos)
                        );
                    } else {
                        console.log('NINGUNO');
                        // var q = query(collection(db, "polizas/" + selectedPoliza + "/usuarios"));
                        var q = query(
                            collection(db, "polizas/" + selectedPoliza + "/usuarios"),
                            where("active", "==", nuevos)
                        );
                    }

                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        var obj = {
                            key: doc.id,
                            id: parseInt(doc.data().afiliado),
                            ref: docSnap.data().id,
                            monto: docSnap.data().monto,
                            deduccion: docSnap.data().deduccion,
                            active: doc.data().active,
                            inicio: doc.data().inicio,
                            fin: doc.data().fin ? doc.data().fin : '',
                            numero: doc.data().numero,
                            referencia: doc.data().referencia,
                            beneficiarios: doc.data().beneficiarios
                        }
                        array.push(obj);
                    });
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log(error)
            }
            setPolizasList(array);
            console.log(array);
            setLoading(false);

        }
        fetchData();
    }, [fechaDesde, fechaHasta, selectedPoliza, nuevos]);

    // useEffect(() => {
    //     async function fetchData() {

    //         console.log(selectedPoliza);
    //         setLoading(true);

    //         try {
    //             const docSnap = await getDoc(doc(db, "polizas", selectedPoliza));
    //             if (docSnap.exists()) {
    //                 var array = [];
    //                 // console.log(nuevos);
    //                 if (fechaDesde && nuevos) {
    //                     var desde = new Date(fechaDesde);
    //                     var hasta = new Date(fechaHasta);
    //                     hasta.setDate(hasta.getDate() + 1);
    //                     console.log('VERDADERO');
    //                     var q = query(
    //                         collection(db, "polizas/" + selectedPoliza + "/usuarios"),
    //                         where("inicio", ">", Timestamp.fromDate(desde)),
    //                         where("inicio", "<", Timestamp.fromDate(hasta)),
    //                         where("active", "==", true)
    //                     );
    //                 } else if (fechaDesde && !nuevos) {
    //                     var desde = new Date(fechaDesde);
    //                     var hasta = new Date(fechaHasta);
    //                     hasta.setDate(hasta.getDate() + 1);
    //                     console.log('FALSO');
    //                     var q = query(
    //                         collection(db, "polizas/" + selectedPoliza + "/usuarios"),
    //                         where("fin", ">", Timestamp.fromDate(desde)),
    //                         where("fin", "<", Timestamp.fromDate(hasta)),
    //                         where("active", "==", false)
    //                     );
    //                 } else {
    //                     console.log('NINGUNO');
    //                     var q = query(collection(db, "polizas/" + selectedPoliza + "/usuarios"));
    //                 }

    //                 const querySnapshot = await getDocs(q);
    //                 querySnapshot.forEach((doc) => {
    //                     var obj = {
    //                         key: doc.id,
    //                         id: parseInt(doc.data().afiliado),
    //                         ref: docSnap.data().id,
    //                         monto: docSnap.data().monto,
    //                         deduccion: docSnap.data().deduccion,
    //                         active: doc.data().active,
    //                         inicio: doc.data().inicio,
    //                         fin: doc.data().fin ? doc.data().fin : '',
    //                         numero: doc.data().numero,
    //                         referencia: doc.data().referencia,
    //                         beneficiarios: doc.data().beneficiarios
    //                     }
    //                     // console.log(doc.id, doc.data().inicio, doc.data().fin);
    //                     // if (nuevos && doc.data().inicio && !doc.data().fin) {
    //                     //     array.push(obj);
    //                     // } else if (nuevos && doc.data().inicio.seconds > doc.data().fin.seconds) {
    //                     //     array.push(obj);
    //                     // } else if (!nuevos && doc.data().fin.seconds > doc.data().inicio.seconds) {
    //                     //     array.push(obj);
    //                     // }
    //                     array.push(obj);
    //                 });
    //             } else {
    //                 console.log("No such document!");
    //             }
    //         } catch (error) {
    //             console.log(error)
    //         }
    //         setPolizasList(array);
    //         console.log(array);
    //         setLoading(false);

    //     }
    //     fetchData();
    // }, [fechaDesde, fechaHasta, selectedPoliza, nuevos]);


    function saveFile(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

    function saveSomeData(string) {
        const blob = new Blob([string], {
            type: "text/csv"
        });
        saveFile(blob, selectedPoliza + ".csv");
    }

    const match = async () => {
        setLoading(true);
        setReady(false);
        try {
            // const q = query(collection(db, "usuarios"), where("institucion", "==", searchValue), where("active", "==", true), orderBy("ingreso", "desc"));
            // if (nuevos) {
            //     var q = query(collection(db, "usuarios"), where("active", "==", true), orderBy("ingreso", "desc"));
            // } else {
            //     var q = query(collection(db, "usuarios"), orderBy("ingreso", "desc"));
            // }

            // var q = query(collection(db, "usuarios"), orderBy("ingreso", "desc"));

            // console.log(polizasGeneralesList.find(p => p.key === selectedPoliza));

            var q = query(collection(db, "usuarios"), where("active", "==", afiliadoActivo), orderBy("ingreso", "desc"));
            // var q = query(collection(db, "usuarios"), where("active", "==", afiliadoActivo), orderBy("alta", "desc"));

            // var q = query(collection(db, "usuarios"), orderBy("ingreso", "desc"));

            const querySnapshot = await getDocs(q);

            var array = [];

            querySnapshot.forEach((doc) => {
                var data = doc.data();
                var birth = data.nacimiento ? new Date(data.nacimiento.seconds * 1000).toLocaleDateString() : '';
                var out = data.desafiliacion ? new Date(data.desafiliacion.seconds * 1000).toLocaleDateString() : '';
                var ingreso = data.ingreso ? new Date(data.ingreso.seconds * 1000).toLocaleDateString() : '';

                var nameSplit = data.nombre.split(" ");
                var lastnameSplit = data.apellidos.split(" ");

                var dataObject = {
                    Vigencia: vigencia,
                    "Tipo de Identificacion": (doc.id.length <= 9) ? '0' : '1',
                    "Identificacion": doc.id,
                    "Primer Apellido": (lastnameSplit.length > 1) ? lastnameSplit[0] : data.apellidos,
                    "Segundo Apellido": (lastnameSplit.length > 1) ? lastnameSplit[1] : data.apellidos,
                    "Primer Nombre": nameSplit[0],
                    "Segundo Nombre": (nameSplit.length > 1) ? nameSplit[1] : '',
                    "Institución": data.institucion,
                    "Genero": data.genero ? data.genero : '',
                    "Fecha de Nacimiento": birth,
                    "Fecha de Afiliacion": ingreso,
                    "Fecha de Desafiliacion": out,
                    "Nacionalidad": data.nacionalidad ? data.nacionalidad : '',
                    "Provincia": data.provincia ? data.provincia : '',
                    "Canton": data.canton ? data.canton : '',
                    "Distrito": data.distrito ? data.distrito : '',
                    "Direccion Completa": '',
                    "Tipo de Telefono": '01',
                    "Telefono": data.contacto1,
                    "Email": data.email,
                    "Suma Asegurada 01": polizasGeneralesList.find(p => p.key === selectedPoliza).monto, // MONTO POLIZA
                    "Suma Asegurada 02": '',
                    "Suma Asegurada 03": '',
                    "Numero de poliza": '',
                    "Prima Recaudada": '',
                    "Tipo de Variacion": '',
                    "Prima Forzada Unidad": '',
                    // key: doc.id,
                    // id: data.id,
                    // cedula: data.id,
                }
                array.push(dataObject);
            });

            var arr = [];

            array.forEach(element => {
                // const equalId = polizasList.filter(item => element.id === item.id);
                // console.log(element.id, parseInt(element['Identificacion']));
                const equalId = polizasList.filter(item => parseInt(element['Identificacion']) === item.id);
                // console.log(element);

                // const equalId = polizasList.filter(item => console.log(element.id));
                var counter = 0;
                // if (!equalId) {
                //   element['Cantidad de polizas'] = counter;
                // }
                // element['Cantidad de polizas'] = counter;

                // element['type'] = 'AFIL';
                equalId.forEach(res => {
                    counter = counter + 1;

                    // element['Cantidad de polizas'] = counter;
                    // element[res.ref + ' ESTADO'] = res.active ? 'ACTIVA' : 'INACTIVA';
                    // element[res.ref + ' Deduccion'] = res.deduccion + ',00';
                    // element[res.ref + ' Monto'] = res.monto;

                    var inicioPoliza = res.inicio ? new Date(res.inicio.seconds * 1000).toLocaleDateString() : '';
                    element[res.ref + ' Inicio'] = inicioPoliza;

                    var finPoliza = res.fin ? new Date(res.fin.seconds * 1000).toLocaleDateString() : '';
                    element[res.ref + ' Fin'] = finPoliza;

                    element[res.ref + ' Referencia'] = res.referencia ? res.referencia : '';

                    // console.log(res);

                    // element['rowType'] = 'AFILIACION';

                    // if (res.active) {
                    //   element.key = 'P' + element.key;
                    //   array.push(element);
                    // }

                    if (res.beneficiarios) {
                        res.beneficiarios.forEach((ele, index) => {
                            element[res.ref + ' Tipo de identificacion ' + (index + 1)] = (ele.id.length <= 9) ? '0' : '1';
                            element[res.ref + ' Identificacion Beneficiario ' + (index + 1)] = ele.id;
                            element[res.ref + ' Nombre Completo Beneficiario ' + (index + 1)] = ele.nombre;
                            element[res.ref + ' Parentesco Beneficiario ' + (index + 1)] = ele.parentesco;
                            element[res.ref + ' Porcentaje Beneficiario ' + (index + 1)] = ele.porcentaje;
                        });
                    }

                    // if (counter > 0) {
                    //   array.push(element);
                    // }
                });

                // if (counter > 0 && element['VTM-525 ESTADO'] === 'ACTIVA') {
                //     const copy = Object.assign({}, element);
                //     copy.key = 'P' + copy.key;
                //     copy['type'] = 'POLIZA';
                //     array.push(copy);
                //     arr.push(copy);
                // }

                if (counter > 0) {
                    const copy = Object.assign({}, element);
                    // copy.key = 'P' + copy.key;
                    // copy['type'] = 'POLIZA';
                    array.push(copy);
                    arr.push(copy);
                }

                // console.log(counter);
            });

            console.log(array);
            console.log(arr);
            setLoading(false);
            setReady(true);
        } catch (error) {
            console.log(error);
            setErrorMsg('Hubo un error');
            setLoading(false);
        }
        setDataList(arr);
        setLoading(false);
        setReady(true);
    }

    const generatedos = () => {
        console.log(dataList);
        let csvContent = "";
        let prevLength = 0;
        var head = '';

        // Obtener las etiquetas de las columnas
        const columnLabels = [...new Set(dataList.flatMap(Object.keys))];

        console.log(columnLabels.join(';'))

        dataList.forEach((element, index) => {
            var objLength = Object.keys(element).length;
            var row = "";

            if (objLength > prevLength) {
                var header = Object.keys(element).join(';');
                head = header + "\r\n";
            }

            prevLength = objLength;



            var i = 0;

            for (const property in element) {
                i++
                if (i !== objLength) {
                    row += element[property] + ";";
                } else {
                    row += element[property];
                }
            }
            csvContent += row + "\r\n";
        });
        saveSomeData(columnLabels.join(';') + "\r\n" + csvContent);
    }

    useEffect(() => {
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var today = now.getFullYear() + "-" + month + "-" + day;
        setFechaHasta(today);

        setVigencia(now.toLocaleDateString());

        // var monthAgo = ("0" + (now.getMonth())).slice(-2);
        // setFechaDesde(now.getFullYear() + '-' + monthAgo + '-' + day);
    }, []);

    const handleClick = () => {
        function convertArrayOfObjectsToCSV(data) {
            // Verificar si el array está vacío
            if (data.length === 0) {
                return '';
            }

            // Obtener las etiquetas de las columnas
            const columnLabels = [...new Set(data.flatMap(Object.keys))];

            console.log(columnLabels);

            // Generar el contenido del archivo CSV
            const csvContent = data.map(row => {
                return columnLabels.map(columnLabel => {
                    const cellValue = row[columnLabel] ? row[columnLabel].toString() : '';
                    if (cellValue.includes(',')) {
                        return `"${cellValue}"`;
                    }
                    return cellValue;
                }).join(',');
            }).join('\n');

            // Generar el archivo CSV
            const csvFile = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Permitir la descarga del archivo CSV
            const downloadLink = document.createElement('a');
            downloadLink.download = 'data.csv';
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }

        // Ejemplo de uso
        const data = [
            { name: 'Juan', age: 30, country: 'Spain' },
            { name: 'Pablo', age: 25, country: 'Argentina', id: 123456789 },
            { name: 'Laura', age: 28, country: 'Mexico' }
        ];

        convertArrayOfObjectsToCSV(data);
    }


    return (
        <div className='page'>
            <h1>Generador INS</h1>

            {/* <button className='filled-button' onClick={handleClick}>Test</button> */}

            <p style={{ marginBottom: '2rem' }}>Genera plantilla para el INS con las nuevas exclusiones e inclusiones. IMPORTANTE: Al abrir esta pagina, esperar unos 10 segundos antes de generar un informa, para que se pueda sincronizar con la base de datos de polizas. Si NO se selecciona "Fecha desde" se generaran todos los afiliados con poliza.</p>

            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Ver</th>
                            <th>Id Unico</th>
                            <th>Display name</th>
                            <th>Monto de poliza</th>
                            <th>Deduccion</th>
                        </tr>

                    </thead>
                    {
                        polizasGeneralesList.map(data =>
                            <tbody key={data.key}>
                                <tr>
                                    <td><button className='icon-button' onClick={() => setSelectedPoliza(data.key)}>Seleccionar</button></td>
                                    <td><code>{data.key}</code></td>
                                    <td>{data.id}</td>
                                    <td>{data.monto}</td>
                                    <td>{data.deduccion}</td>
                                </tr>
                            </tbody>
                        )
                    }
                </table>
            </div>

            <h4 style={{ margin: '1rem 0' }}>Poliza seleccionada: {selectedPoliza}</h4>

            <div style={{ marginBottom: '1rem' }}>
                <h5 style={{ marginBottom: '0.5rem' }}>Estado de afiliacion</h5>
                <button className={afiliadoActivo ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => setAfiliadoActivo(!afiliadoActivo)}>
                    <div className={afiliadoActivo ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                </button>
            </div>

            <div style={{ marginBottom: '1rem' }}>
                {/* {nuevos ? <h5 style={{ marginBottom: '0.25rem' }}>Inclusiones</h5> : <h5 style={{ marginBottom: '0.25rem' }}>Exclusiones</h5>}
                {
                    nuevos ?
                        <p style={{ marginBottom: '0.25rem' }}>Afiliados activos con poliza activa</p> :
                        <p style={{ marginBottom: '0.25rem' }}>Afiliados activos con poliza inactiva</p>
                } */}
                <h5 style={{ marginBottom: '0.5rem' }}>Estado de poliza</h5>
                <button className={nuevos ? "toggle-switch-on" : "toggle-switch-off"} onClick={() => setNuevos(!nuevos)}>
                    <div className={nuevos ? "toggle-switch-circle-on" : "toggle-switch-circle-off"} />
                </button>
            </div>



            <div className='ins-dates-container'>
                <div>
                    <label htmlFor='fechaDesdeCinai_institucion' >Fecha desde</label>
                    <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaDesdeCinai_institucion" name="fechaDesdeCinai_institucion" value={fechaDesde} onChange={e => setFechaDesde(e.target.value)}></input>
                </div>
                <div>
                    <label htmlFor='fechaHastaCinai_institucion' >Fecha hasta</label>
                    <input style={{ marginTop: '.5rem' }} className='input-normal' type="date" id="fechaHastaCinai_institucion" name="fechaHastaCinai_institucion" value={fechaHasta} onChange={e => setFechaHasta(e.target.value)}></input>
                </div>
            </div>

            <div style={{ display: 'flex', gap: '1rem', margin: '1rem 0' }}>
                {
                    loading ?
                        <p>Consultando...</p> :
                        <button className='filled-button' onClick={match}>Generar</button>
                }

                {
                    ready && <button className='filled-button' onClick={generatedos}><img src={xlsx} height="24px" style={{ marginRight: '0.5rem' }} />Descargar</button>
                }

            </div>

            <p style={{ color: 'var(--color-error)' }}>{errorMsg}</p>

            {
                loading && <h4>Por favor espera...</h4>
            }

        </div>
    )
}

// const csvContent = columnLabelsString + '\n' + data.map(row => {
//     // Resto del código...
// }).join('\n');